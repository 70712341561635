.open-popup-button {
  background-color: white;
  color: #37a5f9;
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -100px 0 50px;
  z-index: 10;
}

.open-popup-button:hover {
  background-color: #f0f8ff;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.open-popup-button::before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233498db'%3E%3Cpath d='M9.78 18.65l.28-4.23 7.68-6.92c.34-.31-.07-.46-.52-.19L7.74 13.3 3.64 12c-.88-.25-.89-.86.2-1.3l15.97-6.16c.73-.33 1.43.18 1.15 1.3l-2.72 12.81c-.19.91-.74 1.13-1.5.71L12.6 16.3l-1.99 1.93c-.23.23-.42.42-.83.42z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
}

.popup {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  width: 100dvw;
  max-width: 600px;
  max-height: 70dvh;
  background-color: #ffffff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  z-index: 100;
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  overflow: hidden;
}

.popup.visible {
  transform: translateX(-50%) translateY(0);
}

.popup-header {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  padding: 30px 20px 0;
  z-index: 1;
}

.popup-content {
  flex: 1;
  overflow-y: auto;
  padding: 10px 20px 20px;
}

.close-popup {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 28px;
  cursor: pointer;
  color: #333;
  background: none;
  border: none;
  padding: 5px;
  z-index: 2;
}

.back-popup {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 23px;
  cursor: pointer;
  color: #333;
  background: none;
  border: none;
  padding: 5px;
  z-index: 2;
}

.popup-title {
  font-size: 24px;
  font-weight: 700;
  color: #333;
  margin: 10px 0;
  text-align: center;
}

.popup-button {
  width: 100%;
  padding: 15px;
  height: 52px;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.popup-button span {
  flex-grow: 1;
  text-align: left;
}

.ton-button {
  background-color: #0098ea;
  color: white;
}

.stars-button {
  background: linear-gradient(135deg, #ffd951, #ffb222);
  color: #333;
}

.popup-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.popup-button:disabled {
  cursor: not-allowed;
  transition: none;
  transform: none;
  box-shadow: none;
}

.popup-button:disabled svg,
.popup-button:disabled img {
  opacity: 0.5;
}

.popup-button:disabled::after {
  content: 'Coming soon...';
  position: absolute;
  right: 51px;
  padding: 4px 8px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 8px;
  font-weight: 600;
  color: #4f4e4e;
  font-size: 10px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
}

.wallet-button {
  width: 24px;
  margin-left: -4px;
  position: relative !important;
}

.wallet-button > div {
  margin-top: -14px;
  margin-right: 40px;
}

.connect-wallet-container {
  padding-top: 10px;
}

#ton-connect-button,
.go3572451881 {
  width: 24px !important;
}

/* TonConnect Button Styles */
[data-tc-dropdown-button='true'] {
  min-width: 24px !important;
  width: 24px !important;
  height: 40px !important;
  padding: 0 !important;
  border-radius: 0 !important;
  background: transparent !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: none !important;
  position: relative !important;
}

[data-tc-dropdown-button='true'] div {
  font-size: 0 !important;
  width: 24px !important;
  height: 24px !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M20 12V8H6a2 2 0 0 1-2-2c0-1.1.9-2 2-2h12v4'/%3E%3Cpath d='M4 6v12c0 1.1.9 2 2 2h14v-4'/%3E%3Cpath d='M18 12a2 2 0 0 0-2 2c0 1.1.9 2 2 2h4v-4h-4z'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  display: block !important;
}

[data-tc-dropdown-button='true'] svg {
  display: none !important;
}

[data-tc-dropdown-button='true']:hover {
  background: transparent !important;
  border: none !important;
  transform: none !important;
  box-shadow: none !important;
}

[data-tc-dropdown-content='true'] {
  z-index: 1001 !important;
}

[data-tc-dropdown-container='true'] {
  margin-top: -15px !important;
  position: absolute !important;
  left: 23px !important;
  z-index: 1001 !important;
}

[data-tc-connect-button='true'] {
  width: max-content !important;
}

.symbol {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.symbol img,
.symbol svg {
  width: 100%;
  height: 100%;
}
