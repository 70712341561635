.tappadsContainer {
  background: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.loaderWrapper,
.messageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.spinnerBorder {
  width: 40px;
  height: 40px;
  border: 4px solid #f0f8ff;
  border-top: 4px solid #37a5f9;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.errorMessage {
  color: #37a5f9;
  background-color: white;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  width: 260px;
}

.tasksList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 4px;
}

.taskCard {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  gap: 8px;
  cursor: pointer;
}

.taskIcon {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  object-fit: cover;
}

.taskInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.taskTitle {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.taskDescription {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.taskReward {
  font-size: 14px;
  font-weight: 500;
  color: #37a5f9;
}
