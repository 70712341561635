@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.playScreen {
  position: fixed;
  /* top: calc(var(--tg-content-safe-area-inset-top)); */
  /* top: var(--tg-safe-area-inset-top) ; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 70px;
  /* padding-bottom: calc(var(--tg-content-safe-area-inset-bottom)); */
  /* padding-bottom: var(--tg-safe-area-inset-bottom) ; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to top, rgb(31, 126, 220), rgb(60, 157, 226));
  z-index: 1000;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
}

.image {
  width: 120px;
  height: 120px;
  border-radius: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

.title {
  font-size: 2em;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 1px;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
}

.subtitle {
  font-size: 1.1em;
  font-weight: 400;
  color: #ffffff;
  margin: 0 0 40px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.loaderWrapper {
  width: 80%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loaderContainer {
  width: 100%;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.loaderBar {
  width: 0%;
  height: 100%;
  background: linear-gradient(90deg, #4facfe 0%, #00f2fe 100%);
  border-radius: 10px;
  transition: width 0.5s ease-out;
  position: relative;
  overflow: hidden;
}

.loaderBar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  animation: shimmer 1.5s infinite;
}

.loaderProgress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.loaderText {
  font-size: 1em;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin-top: 5px;
}

.version {
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 0.8em;
  opacity: 0.7;
}
