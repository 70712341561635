.app {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif;
  overflow-x: hidden;
  height: 100dvh;
  width: 100dvw;
  position: absolute;
  top: 0;
  left: 0;
}

.app::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #1a237e 0%, #0d47a1 100%);
  z-index: -1;
}

.screen {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 20px; */
  position: absolute;
  width: 100dvw;
  /* width: calc(100dvw - 40px); */
  left: 0;
  top: 0;
  transition: transform 0.3s ease-in-out;
  will-change: transform;
}

.exitingLeft {
  animation: slideLeft 0.6s ease-in-out forwards;
  z-index: 1;
}

.enteringRight {
  animation: slideRight 0.6s ease-in-out forwards;
  z-index: 2;
  pointer-events: none;
}

.screen .card {
  background: linear-gradient(180deg, #ffffff, #f8f8ff);
  border-radius: 24px;
  width: 90%;
  max-width: 360px;
  text-align: center;
  border: 4px solid #8b27f5;
  box-shadow: 0 12px 40px rgba(139, 39, 245, 0.35);
  animation: cardFloat 6s ease-in-out infinite;
}

.enteringRight .card {
  animation: cardEntrance 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.cardContent {
  padding: 20px;
  text-align: center;
  opacity: 1;
  position: relative;
}

.iconWrapper {
  width: 120px;
  height: 120px;
  margin: 20px auto 1rem;
  background: rgba(139, 39, 245, 0.1);
  border-radius: 50%;
  /* padding: 1.5rem; */
  border: 2px solid rgba(139, 39, 245, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: iconBounce 2s ease-in-out infinite;
}

.iconWrapper svg {
  max-width: 75px;
  filter: drop-shadow(0 0 12px rgba(139, 39, 245, 0.4));
}

.title {
  color: #8b27f5;
  font-size: 2.25rem;
  font-weight: 800;
  margin-bottom: 1rem;
  text-shadow: 0 2px 4px rgba(139, 39, 245, 0.15);
  letter-spacing: -0.5px;
  opacity: 1;
}

.description {
  color: #666666;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  line-height: 1.6;
  font-weight: 500;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
  letter-spacing: 0.2px;
  opacity: 1;
}

.subtitle {
  color: #f5b027;
  font-size: 1.35rem;
  font-weight: 700;
  margin: 1rem 0;
  text-shadow: 0 1px 0 #ffe5a3, 0 2px 8px rgba(245, 176, 39, 0.6),
    0 0 4px rgba(245, 176, 39, 0.8);
  line-height: 1.4;
  background: linear-gradient(45deg, #ffe066, #f5b027);
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffb300;
  -webkit-text-stroke: 1.25px #ff8c00;
  letter-spacing: 0.5px;
  position: relative;
  padding: 0.25em 0.75em;
  border-radius: 4px;
  opacity: 0;
}

.animatedElement {
  opacity: 0;
  animation: fadeIn 0.8s ease-out forwards;
}

@keyframes subtitleAnimation {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.subtitle.animatedElement {
  animation: subtitleAnimation 0.8s ease-out forwards;
  animation-delay: 0.1s;
}

.buttonContainer.animatedElement {
  animation: fadeInButton 0.8s ease-out forwards;
  animation-delay: 0.9s;
}

.subtitle::after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(255, 224, 102, 0.5),
    rgba(245, 176, 39, 0.5)
  );
  border-radius: 4px;
  z-index: -1;
  opacity: 0;
  transform: scale(1.05);
  filter: blur(6px);
  box-shadow: 0 0 20px rgba(255, 186, 0, 0.6),
    inset 0 0 15px rgba(255, 186, 0, 0.4);
  animation: glowPulse 0.75s ease-in-out forwards;
  animation-delay: 0.5s;
}

.buttonContainer {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  opacity: 0;
}

.button {
  background: linear-gradient(to bottom, #2ecc71, #27ae60);
  color: white;
  font-weight: 900;
  padding: 1rem 3rem;
  border-radius: 9999px;
  border: 3px solid #219a52;
  font-size: 1.35rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 6px 20px rgba(46, 204, 113, 0.45);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  position: relative;
  overflow: hidden;
  min-width: 160px;
}

.button.mainButton {
  background: linear-gradient(to bottom, #2ecc71, #27ae60);
  border-color: #219a52;
  box-shadow: 0 6px 20px rgba(46, 204, 113, 0.45);
}

.button.shareButton {
  background: linear-gradient(to bottom, #9b59b6, #8e44ad);
  border-color: #7d3c98;
  box-shadow: 0 6px 20px rgba(155, 89, 182, 0.45);
}

.button:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.button:hover:before {
  left: 100%;
}

.button:hover {
  transform: translateY(-2px) scale(1.02);
  box-shadow: 0 8px 25px rgba(46, 204, 113, 0.55);
}

.button:active {
  transform: translateY(1px) scale(0.98);
}

.button.shareButton:hover {
  box-shadow: 0 8px 25px rgba(155, 89, 182, 0.55);
}

.button:disabled {
  cursor: default;
}

@media (max-width: 400px) {
  .buttonContainer {
    flex-direction: column;
    align-items: stretch;
  }

  .button {
    width: 100%;
  }
}

@keyframes cardEntrance {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes iconBounce {
  0%,
  100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-5px) scale(1.1);
  }
}

@keyframes subtitleGlow {
  0%,
  100% {
    text-shadow: 0 1px 0 #ffe5a3, 0 2px 8px rgba(245, 176, 39, 0.6),
      0 0 4px rgba(245, 176, 39, 0.8);
  }
  50% {
    text-shadow: 0 1px 0 #ffe5a3, 0 4px 16px rgba(245, 176, 39, 0.8),
      0 0 12px rgba(245, 176, 39, 1);
  }
}

@keyframes glowPulse {
  0%,
  100% {
    opacity: 0.3;
    transform: scale(1.05);
    filter: blur(6px);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1);
    filter: blur(8px);
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(100%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes cardFloat {
  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-12px) rotate(0.5deg);
  }
}

@keyframes fadeInButton {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
