.container {
  position: relative;
  height: 100dvh;
  overflow: hidden;
}

.unityContainer {
  width: 100dvw;
  /* height: calc(
    100dvh - var(--tg-content-safe-area-inset-bottom) -
      var(--tg-content-safe-area-inset-top)
  ); */
  height: 100dvh;
  /* height: calc(100dvh - var(--tg-safe-area-inset-bottom) - var(--tg-safe-area-inset-top)); */
  overflow-y: scroll;
}

.popupContainer {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  z-index: 1000;
}
