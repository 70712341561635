body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding-top: calc(
    var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top)
  );
  padding-bottom: var(
    --tg-content-safe-area-inset-bottom + var(--tg-safe-area-inset-bottom)
  ); */

  /* padding-top: var(--tg-content-safe-area-inset-top) ;
  padding-bottom: var(--tg-content-safe-area-inset-bottom) ; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Стили для невидимого скролла */
/* Для WebKit-браузеров (Chrome, Safari, новые версии Edge) */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

/* Для Firefox */
* {
  scrollbar-width: none;
}

/* Для IE и Edge */
* {
  -ms-overflow-style: none;
}

/* Обеспечиваем возможность прокрутки, если содержимое больше видимой области */
body {
  overflow-y: auto;
}

/* Глобальные стили для всего приложения */
html,
body,
#root {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;

  -webkit-tap-highlight-color: transparent;
}

/* Отключаем выделение текста для всех элементов */
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Разрешаем выделение текста в полях ввода и текстовых областях */
input,
textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

/* Запрещаем стандартное поведение при касании на мобильных устройствах */
* {
  -webkit-tap-highlight-color: transparent;
}

/* Отключаем перетаскивание изображений и ссылок */
img,
a {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
