@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.playScreen {
  position: fixed;
  /* top: calc(var(--tg-content-safe-area-inset-top)); */
  /* top: var(--tg-safe-area-inset-top) ; */
  top: 0;
  left: 0;
  width: calc(100dvw - 100px);
  height: 100%;
  padding: 0 50px;
  /* padding-top: 70px; */
  /* padding-bottom: calc(var(--tg-content-safe-area-inset-bottom)); */
  /* padding-bottom: var(--tg-safe-area-inset-bottom) ; */
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
}

/* .image {
  max-width: 100%;
  width: auto;
  object-fit: contain;
}

@container (aspect-ratio < 1) {
  .image {
    height: 100dvh;
    width: auto;
  }
}

@container (aspect-ratio >= 1) {
  .image {
    width: 100%;
    height: auto;
  }
} */

.container {
  width: 100%;
  max-width: 400px;
  bottom: 8%;
  height: 24px;
  position: fixed;
  padding: 2px;
  border-radius: 40px;
  overflow: hidden;
}

@media (max-width: 600px) {
  .container {
    max-width: 340px;
    bottom: 4%;
  }
}

@media (max-width: 400px) {
  .container {
    max-width: 270px;
    height: 15px;
    bottom: 4%;
  }
}

.container::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, #e6e6fa, #a69dd9);
  border-radius: 40px;
  z-index: 1;
}

.container::after {
  content: '';
  position: absolute;
  inset: 3px;
  border-radius: 38px;
  background: linear-gradient(
    180deg,
    #6f658c 0%,
    #6f658c 13%,
    #5a4f7a 17%,
    #423764 85%,
    #3b2167 85%,
    #3b2167 100%
  );
  box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.progressContainer {
  position: absolute;
  height: 76%;
  left: 3.5px;
  top: 3.2px;
  border-radius: 11px;
  overflow: hidden;
  width: calc(100% - 6.8px);
  z-index: 3;
  background: linear-gradient(
    180deg,
    #6f658c 0%,
    #6f658c 13%,
    #5a4f7a 17%,
    #423764 85%,
    #3b2167 85%,
    #3b2167 100%
  );
}

@media (max-width: 400px) {
  .progressContainer {
    height: 64%;
  }
}

.progress {
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.9);
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    180deg,
    #b6f864 0%,
    #b6f864 5%,
    #51d944 25%,
    #1cdd65 75%,
    #1f7a6e 95%,
    #1f7a6e 100%
  );
  transition: width 0.3s ease-in-out;
}
