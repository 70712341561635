.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.content {
  position: relative;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 20px;
  width: calc(100dvw - 80px);
  max-width: 600px;
  max-height: 60dvh;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transform: translateY(20px);
  transition: transform 0.3s ease-out;
  display: flex;
  flex-direction: column;
}

.visible .content {
  transform: translateY(0);
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 28px;
  cursor: pointer;
  color: #333;
  background: none;
  border: none;
  padding: 5px;
  z-index: 1;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: #333;
  margin-bottom: 25px;
}

.body {
  width: 100%;
  flex: 1;
  overflow-y: auto;
  height: calc(58dvh - 90px);
}
