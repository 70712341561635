
.tadsContainer {
  background: #fff
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: -1;
}

.spinnerBorder {
  width: 40px;
  height: 40px;
  border: 4px solid #f0f8ff;
  border-top: 4px solid #37a5f9;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.errorMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #37a5f9;
  background-color: white;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  width: 260px;
}
